import React, { useState } from 'react';
import SortButton from '../../UI/SortButton';

const EmailTeplateByTypeTableHeader = () => {

  return (
    <tr>
        {/* <th>
            Id
        </th> */}
        <th>
            Template Name
        </th>
      {/* <th>
          Subject
      </th> */}
        <th>
            Status
        </th>
        <th>

        </th>
        <th>
          Actions
        </th>
    </tr>
  )
}

export default EmailTeplateByTypeTableHeader;