import React, { useState, useEffect, useContext, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Modal, Button } from "react-bootstrap";
import "jodit";
import "jodit/es2021/jodit.min.css";
import JoditEditor from 'jodit-react';
import TextInput from '../UI/TextInput';
import { UserContext } from 'context/User';
import "./render-style.css";
import { sendTestTemplateEmails, renderEmailTemplate } from '../../api';


const SendTestEmail = ({ onChangeEmailField, userEmailField, replyMessageNotice, showModal, template_keys_exp, handleChangeInputs, messageInfo, setLoading, handleDefaultStatusCodes, handleClose }) => {
    const [showAdvSettings, setShowAdvSettings] = useState('none');
    const [advBtnState, setAdvBtnState] = useState('Show')
    const [bodyContent, setBodyContent] = useState()
    const { handleSubmit } = useForm();
    const userEmailValue = useContext(UserContext).user.email;
    // const [userEmailField, setUserEmailField] = useState(userEmailValue);
    // const body_field = document.getElementsByName('body')
    // const body_text = body_field[0].defaultValue
    const { id, is_active, is_default, subject, name, template_type_id, content } = messageInfo; // ?
    const templateContent = content
    // console.log(templateContent)

    const extractValues = (text) => {
        // Regular expression to match values inside double curly braces
        const regex = /{{\s*(\w+)\s*}}/g;
        // Use matchAll to handle cases with multiple values
        const matches = text.matchAll(regex);
        // Extract values from matches
        const values = [];
        for (const match of matches) {
            values.push(match[1]);
        }
        // console.log(values)
        for (let key in template_keys_exp) {
            if (document.getElementsByName(key)[0]) {
                document.getElementsByName(key)[0].disabled = true
            }
        }
        for (const value of values) {
            if (document.getElementsByName(value)[0]) {
                document.getElementsByName(value)[0].disabled = false
            }
        }
        return values;
    }

    const buttons = [
        "source",
        "preview"
    ]
    const config = {
        readonly: true, // all options from https://xdsoft.net/jodit/docs/,
        showCharsCounter: false,
        showWordsCounter: false,
        showXPathInStatusbar: false,
        toolbarAdaptive: false,
        showTooltip: false,
        buttons: buttons,
    }

    const sendTestEmail = () => {
        testEmailRequest()
        // setLoading(false);
    }
    const renderEmailTemplateRequest = () => {
        let data = {
            body: content,
            template_keys: template_keys_exp
        }
        renderEmailTemplate(data)
            .then(response => {
                // console.log('render content', response.data)
                setBodyContent(response.data);
                setIsMounted(true)
                setLoading(false);
            })
            .catch(error => {
                handleDefaultStatusCodes(error.response.status);
                setLoading(false);
            })
    }

    const testEmailRequest = () => {
        setLoading(true);
        let data_test_email = {
            content: content,
            recipient: userEmailField,
            subject: subject,
            data: template_keys_exp
        }

        console.log(data_test_email)

        sendTestTemplateEmails(data_test_email)
            .then(() => {
                setLoading(false);
                handleClose()
                replyMessageNotice('Success!', 'email', 'Your test email has been sent successfully!')
                // history.push(link);
            })
            .catch(error => {
                handleDefaultStatusCodes(error.response.status);
            });
    }
    const [isMounted, setIsMounted] = useState(false)
    const editorRef = useRef(null);
    useEffect(() => {
        // const editor = editorRef.current?.jodit;

        // if (editor) {
        //   // Enter fullscreen mode automatically
        //   editor.fullscreen.toggle();
        // }
        setLoading(true);
        renderEmailTemplateRequest()
        // setIsMounted(true)
        return () => {
            setIsMounted(false)
        }
    }, []);

    return (
        <>

            {isMounted && <Modal className='modal-render-fullscreen' show={showModal} onHide={handleClose} animation={false} fullscreen='true'>
                <Modal.Header closeButton style={{backgroundColor: '#e2d9c9'}}>
                    <Modal.Title>Template name: {name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <JoditEditor ref={editorRef} value={bodyContent} config={config} /> */}
                    <div
                        dangerouslySetInnerHTML={{ __html: bodyContent }}
                    />
                </Modal.Body>
            </Modal>}
        </>
    )
}

export default SendTestEmail;
