import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';
import "jodit";
import "jodit/es2021/jodit.min.css";
import JoditEditor from 'jodit-react';
import classes from './index.module.scss';
import ScheduleOptions from './ScheduleOptions';


const TemplateBox = ({ typesData, placeholder, id_template_type, messageInfo, handleDefaultStatusCodes, setLoading }) => {
  const { id, is_active, is_default, subject, name, template_type_id, content } = messageInfo; // ?
  const editor = useRef(null);
  const [editor_content, setContent] = useState(content);
  function removeElementsByClass(className) {
    const elements = document.getElementsByClassName(className);

    // Convert HTMLCollection to array to avoid live changes
    const elementsArray = Array.from(elements);

    for (const element of elementsArray) {
      element.parentNode.removeChild(element);
    }
  }
  removeElementsByClass('jodit-status-bar-link');
  useEffect(() => {
    removeElementsByClass('jodit-status-bar-link');
  }, []);
  const buttons = [
    "fullsize",
    "source",
  ]
  const config = {
    readonly: true, // all options from https://xdsoft.net/jodit/docs/,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
    toolbarAdaptive: false,
    buttons: buttons,
  }

  // console.log(typesData)


  return (
    <div className={classes.Message_Box}>
      <div className={classes.Message_Header} style={{ backgroundColor: 'rgb(241, 235, 225)', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <div className={classes.Message_Sub_Header_Left} style={{ padding: '0.2rem' }}>
          <h6>Template Group: <b>{typesData['type_name']}</b></h6>
        </div>
      </div>
      <div className={classes.Message_Header} style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
        <div className={classes.Message_Sub_Header_Left} style={{ padding: '0.2rem' }}>
          <h6>Template Name: <b>{name}</b></h6>
        </div>
        {is_default ?
          <div className={classes.Message_Sub_Header_Right} style={{ padding: '0.2rem' }}>
            <span className={classes.Status} style={{ color: '#fff', backgroundColor: '#233539', fontSize: '0.875em', lineHeight: '1.5', borderRadius: '25px', padding: '1px 10px' }}>Default</span>
          </div>
          : ""}
        <div className={classes.Message_Sub_Header_Right} style={{ padding: '0.2rem' }}>
          <span className={`${is_active ? "responded" : "not_responded"} general`}>
            {is_active ? "Active" : "Inactive"}
          </span>
        </div>
      </div>
      <div className={classes.Message_Sub_Header} style={{ flexWrap: 'wrap', borderBottom: '1px solid #dadada', marginBottom: '10px' }}>
        <div className={classes.Message_Sub_Header_Left} style={{ padding: '0.2rem' }}>
          <h6>Subject: <b>{subject}</b></h6>
        </div>
      </div>
      {/* <div className={classes.Message_Content}>
        <p className="mb-0" dangerouslySetInnerHTML={{ __html: content }}></p>
      </div> */}
      <div
        dangerouslySetInnerHTML={{ __html: content }}
      >

      </div>

      {/* <JoditEditor
        ref={editor}
        value={content}
        config={config}
        // tabIndex={4} // tabIndex of textarea
        // onBlur={(newContent) => { setContent(newContent) }} // preferred to use only this option to update the content for performance reasons
      // onChange={(newContent) => { console.log(newContent)}}
      /> */}
      <div className='mt-5'>
        <ScheduleOptions
          default_state={is_default}
          template_id={id}
          template_type_id={template_type_id}
          handleDefaultStatusCodes={handleDefaultStatusCodes}
          setLoading={setLoading}
        />
      </div>
    </div>
  )
}

export default TemplateBox;