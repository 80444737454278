import React, { useState, useEffect, useContext } from 'react';
import Loading from '../Loading';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation, useHistory } from "react-router-dom";
import TemplateBox from './TemplateBox';
import { emailTemplateByIdPost } from '../../api';
import { somethingWentWrongNotice } from '../UI/Notifications';
import { UserContext } from 'context/User';

const EmailTemplateEdit = ({ setTrigger, trigger }) => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const { setIsLoggedIn, setUser } = useContext(UserContext);
  const query = useQuery();
  const id = query.get("template_id");
  const template_type_id = query.get("template_type_id");
  const [loading, setLoading] = useState(false);
  const [messageInfo, setMessageInfo] = useState({});
  // const [templateKeys, setTemplateKeys] = useState({});
  const history = useHistory();
  const title = 'Edit Email Template'; 

  const logout = () => {
    setIsLoggedIn(false);
    setUser({});
    history.push('/');
  }

  const handleDefaultStatusCodes = (status_code, message) => {
    if (status_code === 440 || status_code === 401) {
      logout()
    } else {
      somethingWentWrongNotice(message ? message : "Inbox");
    }
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    templateEmailRequest()
    setTrigger(!trigger);
  }, []);

  const templateEmailRequest = () => {
    emailTemplateByIdPost(`template_id=${id}`)
      .then(response => {
        // console.log(response.data)
        setMessageInfo(response.data[0]);
        setLoading(false);
      })
      .catch(error => {
        handleDefaultStatusCodes(error.response.status);
        setLoading(false);
      })
  }


  return (
    <section className="section-spaces">
      <Container>
        <Row>
          <Col>
            <h3 className="mb-5">
              {title}
            </h3>
          </Col>
        </Row>
        {Boolean(Object.keys(messageInfo).length) &&  
        <Row>
          <Col>
            <TemplateBox 
              messageInfo={messageInfo}
              // templateKeys={templateKeys}
              id_template_type={template_type_id}
              handleDefaultStatusCodes={handleDefaultStatusCodes}
              setLoading={setLoading}
            />
          </Col>
        </Row>
        }
      </Container>
      {loading && <Loading />}
    </section>  
  )
}

export default EmailTemplateEdit;