import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';
import classes from './index.module.scss';
import ScheduleOptions from './ScheduleOptions';
import moment from 'moment';
import Select from '../UI/Select';
import TextInput from '../UI/TextInput';
import "./styles/style.css";
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { store } from 'react-notifications-component';
import { templateEmailsPut, exmEmailTemplateKeys, emailTemplateKeys } from '../../api';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import ControlledEditor from './ControlledEditor';
import CustomSMSTextArea from "./CustomSMSTextArea";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import SendTestEmail from './SendTestEmail';
import { HashLink } from 'react-router-hash-link';
import { UserContext } from 'context/User';



const TemplateBox = ({ placeholder, id_template_type, messageInfo, handleDefaultStatusCodes, setLoading }) => {
    const { id, is_active, is_default, subject, name, template_type_id, content } = messageInfo; // ?    
    const history = useHistory();
    const link = `/email-template?template_id=${id}&template_type_id=${id_template_type}`;
    const { register, handleSubmit, errors } = useForm();
    const submitRef = useRef(null);
    const [templateActive, setTemplateActive] = useState(is_active);
    const [templateName, setTemplateName] = useState(name);
    const [templateSubject, setTemplateSubject] = useState(subject);
    const [templateKeys, setTemplateKeys] = useState([]);
    const [templateContent, setTemplateContent] = useState(content);
    const [showTestModal, setShowTestModal] = useState(false);
    const [exmtemplateKeys, setExmTemplateKeys] = useState({});
    const userEmailValue = useContext(UserContext).user.email;
    const [userEmailField, setUserEmailField] = useState(userEmailValue);
    const userNameValue = useContext(UserContext).user.username;
    const editorRef = useRef(null);
    const config = {
        // buttons: templateKeys, // This adds the custom buttons to the toolbar
        showCharsCounter: false,
        showWordsCounter: false,
        showXPathInStatusbar: false,
        toolbarAdaptive: false,
        // // safe: false,
        // // full: false,
        // defaultMode: 'source',
        // disablePlugins: 'table,iframe',
        cleanHTML: {
            safe: false,
            // full: false,
            allowTags: false, // Disable automatic tag addition
            removeEmptyBlocks: false, // Prevents the removal of empty tags
            // allowTags: false, // Disable tag filtering
            fillEmptyParagraph: false, // Prevent adding empty paragraphs
            // removeEmptyBlocks: false, // Prevent removing empty blocks
            replaceNBSP: false, // Do not replace non-breaking spaces
            // useIframeSandbox: true,
            removeEmptyTextNode: false,
            removeInvTextNodes: false,
            disableCleanFilter: new Set(['table', 'tr', 'td', 'th', 'tbody', '{%', '%}'])
        },
        preserveNewlines: true,
        // disableCleanHTML: true,

        dtd: {
			/**
			 * Remove extra br element inside block element after pasting
			 */
			removeExtraBr: true,

			/**
			 * Check when inserting a block element if it can be inside another block element (according `blockLimits`)
			 */
			checkBlockNesting: false,

			/**
			 * List of elements that contain other blocks
			 */
			// blockLimits: IDictionary<1>;
		},
        // processPasteHTML: false,
        disableCleanFilter: new Set(['{%', '%}']),
        allowUnknownTags: true,
        enter: 'BR',
        buttons: [
            "undo",
            "redo",
            "|",
            "bold",
            "strikethrough",
            "underline",
            "italic",
            "|",
            "superscript",
            "subscript",
            "|",
            "align",
            "|",
            "ul",
            "ol",
            "outdent",
            "indent",
            "|",
            "font",
            "fontsize",
            "brush",
            "paragraph",
            "|",
            "image",
            "link",
            "table",
            "|",
            "hr",
            "eraser",
            "copyformat",
            "|",
            "fullsize",
            "selectall",
            "preview",
            "|",
            "source",
            "|",],
        extraButtons: templateKeys.map((key) => ({
            name: key.includes('{%') ? 'match_data' : key,
            icon: 'empty', // Using an empty icon so only the text shows
            text: key.includes('{%') ? 'match_data' : key,
            exec: (editor) => {
                editor.s.insertHTML(key.includes('{%') ? `${key}` : `{{ ${key} }}`);
            },
        })),
        toolbarSticky: true, // Disable sticky toolbar for simplicity
    };


    const selectValue = { "ONE_TIME": "Onetime", "DAY": "Daily", "WEEK": "Weekly", "MONTH": "Monthly", "YEAR": "Yearly" };

    const onChangeHandler = (value) => {
        setTemplateActive(value.value)
    };
    const handleMultiChange = (value) => {
        onChangeHandler(value);
        document.activeElement.blur()
    }

    const handleBeforePaste = (event) => {
        // console.log('handle before paste')
        event.preventDefault();
        const clipboardData = event.clipboardData || window.clipboardData;
        const pastedData = clipboardData.getData('text/html') || clipboardData.getData('text/plain');
        if (editorRef.current) {
            editorRef.current.selection.insertHTML(pastedData);
          }
      };

    const somethingWentWrongNotice = (operationName) => {
        store.addNotification({
            title: operationName,
            message: "This field is required",
            type: "danger",
            container: "top-right",
            animationIn: ["animate__animated", "animate__slideInRight"],
            animationOut: ["animate__animated", "animate__slideOutRight"],
            dismiss: {
                duration: 5000,
                onScreen: false
            }
        });
    };
    const replyMessageNotice = (title, type, message) => {
        store.addNotification({
            title: title,
            message: message ? message : "Your data has been updated.",
            type: "success",
            container: "top-right",
            animationIn: ["animate__animated", "animate__slideInRight"],
            animationOut: ["animate__animated", "animate__slideOutRight"],
            dismiss: {
                duration: 5000,
                onScreen: false
            }
        });
    }
    const onSubmits = data => {
        setLoading(true);
        const body = {
            content: templateContent,
            is_active: JSON.parse(templateActive),
            template_type_id: parseInt(id_template_type),
            ...data
        }

        if (body.content === '' || body.content === '<p><br></p>' || body.content === '<p></p>' || body.content === '<p></p>\n') {
            setLoading(false);
            somethingWentWrongNotice("Please fill out Content field")
        } else {
            // console.log(JSON.stringify(body))
            let put_body = JSON.stringify(body)
            templateEmailsPut(id, put_body)
                .then(() => {
                    // handleClose();
                    setLoading(false);
                    replyMessageNotice("Success!")
                    setTimeout(() => history.push(link), 2000);
                    // history.push(link);
                })
                .catch(error => {
                    handleDefaultStatusCodes(error.response.status, error.response.data.detail);
                    // handleClose();
                });
        }
    }

    /* Start Send Test Email */
    const onChangeEmailField = (value) => {
        setUserEmailField(value)
    };
    const onSendTestEmail = (e) => {
        setShowTestModal(true);
        e.preventDefault();
    }
    const handleClose = () => {
        setShowTestModal(false);
    }

    const templateKeysExmRequest = () => {
        exmEmailTemplateKeys()
            .then(response => {
                let temp_data = response.data.email
                temp_data.user_email = userEmailValue
                temp_data.user_name = userNameValue
                setExmTemplateKeys(response.data.email);
                // setIsMounted(true)
                setLoading(false);
            })
            .catch(error => {
                handleDefaultStatusCodes(error.response.status);
                setLoading(false);
            })
    }

    const emailTemplateKeysRequest = (id_template_type) => {
        emailTemplateKeys()
          .then(response => {
            let response_key = response.data
            // console.log(response_key[id_template_type])
            setTemplateKeys(response_key[id_template_type]);
            setIsMounted(true)
            setLoading(false);
          })
          .catch(error => {
            handleDefaultStatusCodes(error.response.status);
            setLoading(false);
          })
    }

    const handleChangeInputs = (name, event) => {
        // Create a copy of the addresses object using spread syntax
        const updatedTemplateKeys = { ...exmtemplateKeys };
        // Update the value for the specified name
        updatedTemplateKeys[name] = event.target.value;
        // Set the updated state
        setExmTemplateKeys(updatedTemplateKeys);
    };

    const [isMounted, setIsMounted] = useState(false)

    useEffect(() => {
        templateKeysExmRequest()
        emailTemplateKeysRequest(id_template_type)
        // setIsMounted(true)
        return () => {
            setIsMounted(false)
        }
    }, [])
    /* End Send Test Email */

    return (
        <div className={classes.Message_Box}>
            <form onSubmit={handleSubmit(onSubmits)}>
                <fieldset>
                    <label htmlFor="name">Template Name</label>
                    <TextInput
                        name="name"
                        value={templateName}
                        onChange={(event) => setTemplateName(event.target.value)}
                        register={register()}
                        required={true}
                        check={is_default ? false : true}
                    />
                </fieldset>
                <fieldset>
                    <label htmlFor="subject">Subject</label>
                    <TextInput
                        name="subject"
                        value={templateSubject}
                        onChange={(event) => setTemplateSubject(event.target.value)}
                        register={register()}
                        required={true}
                        check={is_default ? false : true}
                    />
                </fieldset>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    <fieldset>
                        <label htmlFor="is_active">Status</label>
                        <Select
                            handleMultiChange={handleMultiChange}
                            name="is_active"
                            list={
                                [
                                    { value: "false", label: "Inactive" },
                                    { value: "true", label: "Active" },
                                ]
                            }
                            styles="react-select-input-container"
                            prefixStyles="react-select-input"
                            indicator={false}
                            isSearchable={false}
                            isClear={false}
                            defaultValue={{ value: templateActive, label: "Inactive" }}
                        />
                    </fieldset>
                </div>
                {!is_default &&
                <fieldset>
                    <div className='mb-5'>
                        <label htmlFor="content">Content</label>
                        {isMounted &&<JoditEditor
                            ref={editorRef}
                            value={templateContent}
                            config={config}
                            // tabIndex={4} // tabIndex of textarea
                            onBlur={(newContent) => setTemplateContent(newContent)} // preferred to use only this option to update the content for performance reasons
                            // onBeforePaste={handleBeforePaste}
                            // onChange={(newContent) => setTemplateContent(newContent)}
                        />}
                    </div>
                </fieldset>} 
                {is_default &&
                <fieldset>
                    <div
                      dangerouslySetInnerHTML={{ __html: templateContent }}
                    ></div>
                </fieldset>
                }
                <div className='mt-2'>
                    <ScheduleOptions
                        id_template_type={template_type_id}
                        submitRef={submitRef}
                    />
                </div>
            </form>
            <HashLink
                onClick={(e) => onSendTestEmail(e)}
                to='#'
                className="btnSizes transparentBtn mt-4"
                smooth
            >
                Send Test Email
            </HashLink>
            {isMounted && (showTestModal && <SendTestEmail userEmailField={userEmailField} onChangeEmailField={onChangeEmailField} replyMessageNotice={replyMessageNotice} template_keys_exp={exmtemplateKeys} handleChangeInputs={handleChangeInputs} handleDefaultStatusCodes={handleDefaultStatusCodes} templateContent={templateContent} templateSubject={templateSubject} setLoading={setLoading} showModal={showTestModal} handleClose={handleClose} />)}
        </div>
    )
}

export default TemplateBox;