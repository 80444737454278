import React, { useState, useEffect, useContext } from 'react';
import Loading from '../Loading';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation, useHistory } from "react-router-dom";
import TemplateBox from './TemplateBox';
import { emailTemplateByIdPost, exmEmailTemplateKeys, emailTemplatesTypesPost } from '../../api';
import { somethingWentWrongNotice } from '../UI/Notifications';
import { UserContext } from 'context/User';
import { HashLink } from 'react-router-hash-link';
import SendTestEmail from './SendTestEmail';


const EmailTemplateView = ({ setTrigger, trigger }) => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const { setIsLoggedIn, setUser } = useContext(UserContext);
  const query = useQuery();
  const id = query.get("template_id");
  const template_type_id = query.get("template_type_id");
  const [loading, setLoading] = useState(false);
  const [messageInfo, setMessageInfo] = useState({});
  const [typesData, setTypesData] = useState({})
  const [showTestModal, setShowTestModal] = useState(false);
  const [exmtemplateKeys, setExmTemplateKeys] = useState({});
  const userEmailValue = useContext(UserContext).user.email;
  const [userEmailField, setUserEmailField] = useState(userEmailValue);
  const userNameValue = useContext(UserContext).user.username;
  const history = useHistory();
  const title = 'Email Template'; 

  const logout = () => {
    setIsLoggedIn(false);
    setUser({});
    history.push('/');
  }

  const handleDefaultStatusCodes = (status_code) => {
    if (status_code === 440 || status_code === 401) {
      logout()
    } else {
      somethingWentWrongNotice("Something went wrong");
    }
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    templateEmailRequest()
    templateKeysExmRequest()
    templateTypeRequest()
    setTrigger(!trigger);
    // setIsMounted(true)
    return () => {
      setIsMounted(false)
    }
  }, []);

  const templateEmailRequest = () => {
    emailTemplateByIdPost(`template_id=${id}`)
      .then(response => {
        // console.log(response.data)
        setMessageInfo(response.data[0]);
        setLoading(false);
      })
      .catch(error => {
        handleDefaultStatusCodes(error.response.status);
        setLoading(false);
      })
  }
  const templateTypeRequest = () => {
    emailTemplatesTypesPost()
      .then(response => {
        let foundObject = response.data.find(item => item.id === parseInt(template_type_id));
        // console.log(foundObject)
        setTypesData(foundObject);
        // setTotalCount(response.data.total_count);
        // setSorted(false);
        setLoading(false);
      })
      .catch(error => {
        error.response.status === 404 ?
        setTypesData([]) :
          handleDefaultStatusCodes(error.response.status);
        setLoading(false);
      })
  }

    /* Start Send Test Email */
    const onChangeEmailField = (value) => {
      setUserEmailField(value)
    };
    const onSendTestEmail = (e) => {
      setShowTestModal(true);
      e.preventDefault();
    }
    const handleClose = () => {
      setShowTestModal(false);
    }
  
    const templateKeysExmRequest = () => {
      exmEmailTemplateKeys()
        .then(response => {
          let temp_data = response.data.email
          temp_data.user_email = userEmailValue
          temp_data.user_name = userNameValue
          setExmTemplateKeys(response.data.email);
          setIsMounted(true)
          setLoading(false);
        })
        .catch(error => {
          handleDefaultStatusCodes(error.response.status);
          setLoading(false);
        })
    }

    const replyMessageNotice = (title, type, message) => {
      store.addNotification({
          title: title,
          message: message ? message : "Your data has been updated.",
          type: "success",
          container: "top-right",
          animationIn: ["animate__animated", "animate__slideInRight"],
          animationOut: ["animate__animated", "animate__slideOutRight"],
          dismiss: {
              duration: 5000,
              onScreen: false
          }
      });
  }
  
    const handleChangeInputs = (name, event) => {
      // Create a copy of the addresses object using spread syntax
      const updatedTemplateKeys = { ...exmtemplateKeys };
      // Update the value for the specified name
      updatedTemplateKeys[name] = event.target.value;
      // Set the updated state
      setExmTemplateKeys(updatedTemplateKeys);
    };
  
    const [isMounted, setIsMounted] = useState(false)


  return (
    <section className="section-spaces">
      <Container>
        <Row>
          <Col>
          <div className="mb-5" style={{display: 'flex', justifyContent: 'space-between'}}>
            <h3>
                {title}
            </h3>
            <HashLink
                onClick={(e) => onSendTestEmail(e)}
                to='#'
                className="btnSizes filledBtn"
                smooth
            >
                Preview Email
            </HashLink>
            {isMounted && (showTestModal && <SendTestEmail userEmailField={userEmailField} onChangeEmailField={onChangeEmailField} replyMessageNotice={replyMessageNotice} template_keys_exp={exmtemplateKeys} handleChangeInputs={handleChangeInputs} handleDefaultStatusCodes={handleDefaultStatusCodes} messageInfo={messageInfo} setLoading={setLoading} showModal={showTestModal} handleClose={handleClose} />)}
          </div>
          </Col>
        </Row>
        <Row>
          <Col>
          {isMounted && (<TemplateBox 
              messageInfo={messageInfo}
              typesData={typesData}
              id_template_type={template_type_id}
              handleDefaultStatusCodes={handleDefaultStatusCodes}
              setLoading={setLoading}
            />)}
          </Col>
        </Row>
      </Container>
      {loading && <Loading />}
    </section>  
  )
}

export default EmailTemplateView;