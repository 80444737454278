import React, { useState, useContext } from 'react';
import classes from '../index.module.scss';
import { SearchContext } from '../../../context/Search';
import moment from 'moment';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import DeleteReport from '../../ScheduleEmails/DeleteReport'

const EmailTemplatesTypesTableBody = () => {
  const { searchedData } = useContext(SearchContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setDeleteId('');
  }

  const onDeleteReport = (e, id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
    e.preventDefault();
  }
  
  return (
    <>
      {
        searchedData.map(item => {
          const {id, template_count, type_name } = item;  

          return (
            <tr 
              key={id}
            >
              {/* <td className={`${classes.Title_Bold} ${classes.Vertical_Middle}`}>{id}</td> */}
              <td className={`${classes.Title_Normal} ${classes.Vertical_Middle}`}>{type_name}</td>
              <td className={`${classes.Title_Bold} ${classes.Vertical_Middle}`}><span style={{color: '#fff', backgroundColor: '#233539', fontSize: '0.875em', lineHeight: '1.5', borderRadius: '25px', padding: '3px 10px', marginRight: '5px'}}>{template_count}</span></td>
              <td className={classes.Table_Options} style={{display: 'flex'}}>
                <Link
                  to={`/email-template-by-type?template_email_by_type=${id}`}
                  className="btnSizes filledBtn"
                >
                  View Templates
                </Link>
                {/* <Link
                  to={`/update-email-schedule?template_id=${template_id}&type=email`}
                  className="btnSizes transparentBtn ml-2"
                >
                  Edit
                </Link>
                <HashLink
                  onClick={(e) => onDeleteReport(e, template_id)}
                  to='#'
                  className="btnSizes defaultBtn ml-2"
                  smooth
                >
                  Delete
                </HashLink> */}
              </td>
            </tr> 
          )
        })
      }

    {/* { showDeleteModal && <DeleteReport template_id={deleteId} closeDeleteModal={closeDeleteModal} showDelete={showDeleteModal}/> } */}

    </>
  )
}

export default EmailTemplatesTypesTableBody;
 