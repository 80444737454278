import React, { useState } from 'react';
import SortButton from '../../UI/SortButton';

const EmailTemplatesTypesTableHeader = () => {

  const [sortedBy, setSortedBy] = useState({
    created: null,
    template_title: null,
    last_triggered: null,
    scheduled_send_time: null,
    is_active: null,
    recurrence_frequency: null,
    recurrence_interval: null,

  });

  return (
    <tr>
        {/* <th>
            Id
        </th> */}
        <th>
            Template Group Names
        </th>
        <th>
            Number of templates
        </th>
      <th>
        Actions
      </th>
    </tr>
  )
}

export default EmailTemplatesTypesTableHeader;