import React, { useContext, useState, useEffect } from "react";
import { UserContext } from '../../context/User';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation, useHistory } from "react-router-dom";
import { Link } from 'react-router-dom';
import { somethingWentWrongNotice } from '../UI/Notifications';
import { SearchContext } from '../../context/Search';
import SearchSettings from "../SearchSettings";
import TableContainer from "../TableContainer";
import Pagination from "../Pagination";
import PageSwitcher from "../PageSwitcher";
import Loading from '../Loading';
import { emailTemplateByTypePost, emailTemplatesTypesPost } from '../../api';


const EmailTemplateByType = () => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const query = useQuery();
  const id = query.get("template_email_by_type");
  const [loading, setLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(null);
  const [offset, setOffset] = useState(0);
  const [active, setActive] = useState("template_email_by_type")
  const [limit, setLimit] = useState(10);
  const [pageCount, setPageCount] = useState(0);
  const [searchedData, setSearchedData] = useState([]);
  const [typesData, setTypesData] = useState({})
  const [forcePage, setForcePage] = useState(0);
  const { setUser, setIsLoggedIn } = useContext(UserContext);
  const history = useHistory();
  const [sorted, setSorted] = useState(false);
  const [orderByParams, setOrderByParams] = useState([]);
  const [currentInputValues, setCurrentInputValues] = useState({
    search: ""
  });
  const [searchTrigger, setSearchTrigger] = useState(false);

  const logout = () => {
    setIsLoggedIn(false);
    setUser({});
    history.push('/');
  }

  const handleDefaultStatusCodes = (status_code) => {
    if (status_code === 440 || status_code === 401) {
      logout()
    } else {
      somethingWentWrongNotice("Email Templates");
    }

    setLoading(false);
  }

  const dataStructuring = (id) => {
    setLoading(true);

    // if (!fullTextParams.search) delete fullTextParams.search;

    // requestHandler(fullTextParams, {});
    requestHandler(id);

  }

  const onSubmit = (id) => {

    dataStructuring(id);
  }

  const sortHandler = (sort_by) => {
    const fullTextParams = {
      limit: limit,
      offset: offset,
      order_by: sort_by && sort_by,
    };

    if (!fullTextParams.order_by) delete fullTextParams.order_by;

    dataStructuring(fullTextParams);
  }

  useEffect(() => {
    if (sorted) sortHandler(orderByParams);
    if (!sorted) onSubmit(id);
  }, [limit, offset, orderByParams, searchTrigger])

  const requestHandler = (id) => {
    let req_id = `template_type_id=${id}`
    emailTemplateByTypePost(req_id)
      .then(response => {
        // console.log(response)
        setSearchedData(response.data);
        // setTotalCount(response.data.total_count);
        // setSorted(false);
        setLoading(false);
      })
      .catch(error => {
        error.response.status === 404 ?
          setSearchedData([]) :
          handleDefaultStatusCodes(error.response.status);
        setLoading(false);
      })
    emailTemplatesTypesPost()
      .then(response => {
        let foundObject = response.data.find(item => item.id === parseInt(id));
        // console.log(foundObject)
        setTypesData(foundObject);
        // setTotalCount(response.data.total_count);
        // setSorted(false);
        setLoading(false);
      })
      .catch(error => {
        error.response.status === 404 ?
        setTypesData([]) :
          handleDefaultStatusCodes(error.response.status);
        setLoading(false);
      })
  }

  const onChangeSearchHandler = event => {
    setCurrentInputValues({
      ...currentInputValues,
      search: event.target.value
    })
  }

  return (
    <>
      <section className="section-spaces">
        <Container>
          <Row>
            <Col>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <h3 className="mb-5">
                {typesData['type_name']} Templates
              </h3>
              <Link
                  to={`/create-email-template?template_email_by_type=${id}`}
                  className="btnSizes mb-5 text-right filledBtn"
                >
                  Create New Email Template
              </Link>
            </div>
              {!searchedData.length && (
                <h3 className="my-5">No results</h3>
              )}
              <SearchContext.Provider value={{
                setOrderByParams,
                orderByParams,
                setSorted,
                sorted,
                setSearchedData,
                searchedData,
                totalCount,
                limit,
                offset,
                setLimit,
                setOffset,
                forcePage,
                setForcePage,
                pageCount,
                setPageCount,
                loading,
                setLoading,
                currentInputValues,
                setCurrentInputValues,
                active,
                onChangeSearchHandler,
                onSubmit,
                setSearchTrigger,
                searchTrigger
              }}
              >
                {!!searchedData && !!searchedData.length && (
                  <>
                    {/* <SearchSettings /> */}
                    <div style={{justifyContent: 'center'}}>
                      <TableContainer />
                    </div>
                    <div className="mt-5">
                      <Link
                        to={`/email-templates-types`}
                        className="btnSizes filledBtn"
                      >
                        All Template Groups
                      </Link>
                </div>
                  </>
                )}
                {searchedData && !!searchedData.length && !!totalCount && (
                  <div className="pagination-wrap">
                    <Pagination />
                    <PageSwitcher />
                  </div>
                )}
              </SearchContext.Provider>
              {loading && <Loading />}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default EmailTemplateByType;