import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';
import JoditEditor from 'jodit-react';
// import JoditEditor from "jodit-pro-react";
import classes from './index.module.scss';
import ScheduleOptions from './ScheduleOptions';
import moment from 'moment';
import Select from '../UI/Select';
import TextInput from '../UI/TextInput';
import TextArea from '../UI/TextArea';
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { convertFromRaw, EditorState, convertToRaw } from "draft-js";
import localization from "./config/localization";
import toolbar from "./config/toolbar";
import "./styles/style.css";
import CustomComponent from "./CustomComponent";
import CustomSMSTextArea from "./CustomSMSTextArea";
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { templateEmailPost, exmEmailTemplateKeys, emailTemplateKeys, emailTemplatesTypesPost } from '../../api';
import { useForm } from 'react-hook-form';
import { store } from 'react-notifications-component';
import { useHistory } from 'react-router-dom';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import SendTestEmail from './SendTestEmail';
import { HashLink } from 'react-router-hash-link';
import { UserContext } from 'context/User';


const TemplateBox = ({ placeholder, type, id_template_type, templateKey, handleDefaultStatusCodes, setLoading }) => {
    const editor = useRef(null);
    const { register, handleSubmit, errors } = useForm();
    const [templateActive, setTemplateActive] = useState('false');
    const [templateName, setTemplateName] = useState('');
    const [templateSubject, setTemplateSubject] = useState('');
    const [templateKeys, setTemplateKeys] = useState(templateKey);
    const [typesData, setTypesData] = useState([]);
    const [typeId, setTypeId] = useState(id_template_type ? id_template_type : '');
    const [templateContent, setTemplateContent] = useState('');
    const [showTestModal, setShowTestModal] = useState(false);
    const [exmtemplateKeys, setExmTemplateKeys] = useState({});
    const userEmailValue = useContext(UserContext).user.email;
    const [userEmailField, setUserEmailField] = useState(userEmailValue);
    const userNameValue = useContext(UserContext).user.username;
    const submitRef = useRef(null);
    const history = useHistory();
    const link = `/email-template-by-type?template_email_by_type=${id_template_type ? id_template_type : typeId}`;

    // console.log('typeId', typeId)


    const onChangeHandler = (value) => {
        setTemplateActive(value.value)
    };
    const handleMultiChange = (value) => {
        onChangeHandler(value);
        document.activeElement.blur()
    }

    const onChangeHandlerType = (value) => {
        // console.log(value.value)
        
        setTypeId(value.value)
        // console.log('typeId', typeId)
        // console.log('onchange', templateKeys[value.value])
        setConfig(getConfig(templateKeys, value.value));
    };
    const handleMultiChangeType = (value) => {
        onChangeHandlerType(value);
        document.activeElement.blur()
    }

    const somethingWentWrongNotice = (operationName) => {
        store.addNotification({
            title: operationName,
            message: "This field is required",
            type: "danger",
            container: "top-right",
            animationIn: ["animate__animated", "animate__slideInRight"],
            animationOut: ["animate__animated", "animate__slideOutRight"],
            dismiss: {
                duration: 5000,
                onScreen: false
            }
        });
    };
    const replyMessageNotice = (title, type, message) => {
        store.addNotification({
            title: title,
            message: message ? message : `Your Email Template has been created.`,
            type: "success",
            container: "top-right",
            animationIn: ["animate__animated", "animate__slideInRight"],
            animationOut: ["animate__animated", "animate__slideOutRight"],
            dismiss: {
                duration: 5000,
                onScreen: false
            }
        });
    }
    const onSubmit = data => {
        setLoading(true);

        const body = {
            content: templateContent,
            is_active: JSON.parse(templateActive),
            template_type_id: id_template_type ? parseInt(id_template_type) : parseInt(typeId),
            ...data
        }
        if (!typeId) {
            setLoading(false)
            somethingWentWrongNotice("Please select Template Group")
        } else if (body.content === '' || body.content === '<p><br></p>' || body.content === '<p></p>' || body.content === '<p></p>\n') {
            setLoading(false)
            somethingWentWrongNotice("Please fill out Content field")
        }   
        else {
            // console.log(JSON.stringify(body))
            templateEmailPost(JSON.stringify(body))
                .then((response) => {
                    // handleClose();
                    // console.log('response -> ', response.data)
                    // console.log(response.data[0].id, response.data[0].template_type_id)
                    setLoading(false);
                    replyMessageNotice("Success!", type)
                    // setTimeout(() => history.push(link), 2000);
                    setTimeout(() => history.push(`email-template?template_id=${response.data[0].id}&template_type_id=${response.data[0].template_type_id}`), 2000);
                    
                })
                .catch(error => {
                    console.log(error.response)
                    handleDefaultStatusCodes(error.response.status, error.response.data.detail);
                    // handleClose();
                });
        }


    }
    /* Start Send Test Email */
    const onChangeEmailField = (value) => {
        setUserEmailField(value)
    };
    const onSendTestEmail = (e) => {
        setShowTestModal(true);
        e.preventDefault();
    }
    const handleClose = () => {
        setShowTestModal(false);
    }

    const templateKeysExmRequest = () => {
        exmEmailTemplateKeys()
            .then(response => {
                let temp_data = response.data.email
                temp_data.user_email = userEmailValue
                temp_data.user_name = userNameValue
                setExmTemplateKeys(response.data.email);
                // setIsMounted(false)
                setLoading(false);
            })
            .catch(error => {
                handleDefaultStatusCodes(error.response.status);
                setLoading(false);
            })
    }

    const handleChangeInputs = (name, event) => {
        // Create a copy of the addresses object using spread syntax
        const updatedTemplateKeys = { ...exmtemplateKeys };
        // Update the value for the specified name
        updatedTemplateKeys[name] = event.target.value;
        // Set the updated state
        setExmTemplateKeys(updatedTemplateKeys);
    };


    // console.log(templateKeys)

    const [isMounted, setIsMounted] = useState(false)

    const getConfig = (templateKeys, id) => ({
        readonly: false,
        showCharsCounter: false,
        showWordsCounter: false,
        showXPathInStatusbar: false,
        toolbarAdaptive: false,
        safe: false,
        full: false,
        // defaultMode: 'source',
        // disablePlugins: 'table,iframe',
        cleanHTML: {
            safe: false,
            full: false,
            allowTags: false, // Disable automatic tag addition
            removeEmptyBlocks: false, // Prevents the removal of empty tags
            // allowTags: false, // Disable tag filtering
            fillEmptyParagraph: false, // Prevent adding empty paragraphs
            // removeEmptyBlocks: false, // Prevent removing empty blocks
            replaceNBSP: false, // Do not replace non-breaking spaces
            // useIframeSandbox: true,
            removeEmptyTextNode: false,
            removeInvTextNodes: false,
            // entities: false,
            // disableCleanFilter: new Set(['table', 'tr', 'td', 'th', 'tbody', '{%', '%}'])
        },
        preserveNewlines: true,
        disableCleanHTML: true,

        dtd: {
			/**
			 * Remove extra br element inside block element after pasting
			 */
			removeExtraBr: true,

			/**
			 * Check when inserting a block element if it can be inside another block element (according `blockLimits`)
			 */
			checkBlockNesting: false,

			/**
			 * List of elements that contain other blocks
			 */
			// blockLimits: IDictionary<1>;
		},
        // processPasteHTML: false,
        disableCleanFilter: new Set(['{%', '%}']),
        allowUnknownTags: true,
        enter: 'BR',
        minHeight: 400,
        tabIndex: 1,
        buttons: [
            "undo",
            "redo",
            "|",
            "bold",
            "strikethrough",
            "underline",
            "italic",
            "|",
            "superscript",
            "subscript",
            "|",
            "align",
            "|",
            "ul",
            "ol",
            "outdent",
            "indent",
            "|",
            "font",
            "fontsize",
            "brush",
            "paragraph",
            "|",
            "image",
            "link",
            "table",
            "|",
            "hr",
            "eraser",
            "copyformat",
            "|",
            "fullsize",
            "selectall",
            "preview",
            "|",
            "source",
            "|",],
        extraButtons: id ? templateKeys[id].map((key) => ({
            name: key.includes('{%') ? 'match_data' : key,
            icon: 'empty', // Using an empty icon so only the text shows
            text: key.includes('{%') ? 'match_data' : key,
            exec: (editor) => {
                editor.s.insertHTML(key.includes('{%') ? `${key}` : `{{ ${key} }}`);
            },
            // tooltip: `Insert ${key}`
        })) : false,
        toolbarSticky: true, // Disable sticky toolbar for simplicity
        // uploader: {
        //     insertImageAsBase64URI: true
        // },
        // filebrowser: {
        //     ajax: {
        //         url: 'https://xdsoft.net/jodit/finder/'
        //     },
        //     height: 580,
        // }
    });

    // const emailTemplateKeysRequest = () => {
    //     emailTemplateKeys()
    //         .then(response => {
    //             // if (id_template_type) {
    //                 let response_key = response.data
    //                 // console.log(response_key[id_template_type])
    //                 // setTemplateKeys(response_key[id_template_type]);
    //             // } else {
    //                 // let response_key = response.data
    //                 // console.log(response_key)
    //                 setTemplateKeys(response_key);
    //                 console.log('2 v', typeId, templateKeys)
    //                 setConfig(getConfig(response_key, typeId))
    //             // }
                
    //             setLoading(false);
    //         })
    //         .catch(error => {
    //             handleDefaultStatusCodes(error.response.status);
    //             setLoading(false);
    //         })
    // }

    const requestHandler = () => {
        emailTemplatesTypesPost()
          .then(response => {
            // console.log('response ->', response)
            setTypesData(response.data);
            setIsMounted(true)
            setLoading(false);
          })
          .catch(error => {
            error.response.status === 404 ?
              setTypesData([]) :
              handleDefaultStatusCodes(error.response.status);
            setLoading(false);
          })
      }


    useEffect(() => {
        templateKeysExmRequest()
        requestHandler()
        // emailTemplateKeysRequest()
        // console.log('2 v', typeId, templateKeys)
        // setConfig(getConfig(templateKeys, typeId))
        // setIsMounted(true)
        return () => {
            setIsMounted(false)
        }
    }, [])

    // console.log('typesData', typesData)
    const [config, setConfig] = useState(getConfig(templateKeys, typeId));
    /* End Send Test Email */

    return (
        <div className={classes.Message_Box}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <fieldset>
                    <label htmlFor="name">Template Name</label>
                    <TextInput
                        name="name"
                        value={templateName}
                        onChange={(event) => setTemplateName(event.target.value)}
                        register={register()}
                        required={true}
                    />
                </fieldset>
                <fieldset>
                    <label htmlFor="subject">Subject</label>
                    <TextInput
                        name="subject"
                        value={templateSubject}
                        onChange={(event) => setTemplateSubject(event.target.value)}
                        register={register()}
                        required={true}
                    />
                </fieldset>
                <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                    <fieldset>
                        <label htmlFor="is_active">Status</label>
                        <Select
                            handleMultiChange={handleMultiChange}
                            name="is_active"
                            list={
                                [
                                    { value: "false", label: "Not Active" },
                                    { value: "true", label: "Active" },
                                ]
                            }
                            styles="react-select-input-container"
                            prefixStyles="react-select-input"
                            indicator={false}
                            isSearchable={false}
                            isClear={false}
                            defaultValue={{ value: templateActive, label: "Not Active" }}
                        />
                    </fieldset>
                    {!id_template_type && isMounted && <fieldset>
                        <label htmlFor="template_type_id">Template Group</label>
                        <Select
                            handleMultiChange={handleMultiChangeType}
                            name="template_type_id"
                            list={
                                typesData.map(type_data =>{
                                    return {value: type_data.id, label: type_data. type_name}
                                })
                            }
                            styles="react-select-input-container"
                            prefixStyles="react-select-input"
                            indicator={false}
                            isSearchable={false}
                            isClear={false}
                            // defaultValue={{ value: templateActive, label: "Not Active" }}
                        />
                    </fieldset>}
                    {id_template_type && isMounted && <fieldset>
                        <label htmlFor="type_id">Template Group</label>
                        <Select
                            handleMultiChange={handleMultiChangeType}
                            name="template_type_id"
                            disabled={true}
                            // list={() => {let foundObject = typesData.find(item => item.id === parseInt(id_template_type)); return [{ value: foundObject.id, label: foundObject.type_name }]}}
                            styles="react-select-input-container"
                            prefixStyles="react-select-input"
                            indicator={false}
                            isSearchable={false}
                            isClear={false}
                            defaultValue={{ value: typesData.find(item => item.id === parseInt(id_template_type)).id, label: typesData.find(item => item.id === parseInt(id_template_type)).type_name }}
                        />
                    </fieldset>}
                </div>
                <fieldset>
                    <div className='mb-5'>
                        <label htmlFor="content">Content</label>
                        {isMounted && <JoditEditor
                            ref={editor}
                            value={templateContent}
                            config={config}
                            key={config.buttons.join(',')}
                            // style={{ height: '300px' }}
                            // tabIndex={4} // tabIndex of textarea
                            onBlur={(newContent) => setTemplateContent(newContent)} // preferred to use only this option to update the content for performance reasons
                        // onChange={(newContent) => { }}
                        />}
                    </div>
                </fieldset>
                <div className='mt-2'>
                    <ScheduleOptions
                        type={type}
                        template_type_id={id_template_type}
                        submitRef={submitRef}
                        handleDefaultStatusCodes={handleDefaultStatusCodes}
                        setLoading={setLoading}
                    />
                </div>
            </form>
            <HashLink
                onClick={(e) => onSendTestEmail(e)}
                to='#'
                className="btnSizes transparentBtn mt-4"
                smooth
            >
                Send Test Email
            </HashLink>
            {/* {isMounted && (showTestModal && <SendTestEmail userEmailField={userEmailField} onChangeEmailField={onChangeEmailField} replyMessageNotice={replyMessageNotice} template_keys_exp={exmtemplateKeys} handleChangeInputs={handleChangeInputs} handleDefaultStatusCodes={handleDefaultStatusCodes} setLoading={setLoading} showModal={showTestModal} handleClose={handleClose} />)} */}
            {isMounted && (showTestModal && <SendTestEmail userEmailField={userEmailField} onChangeEmailField={onChangeEmailField} replyMessageNotice={replyMessageNotice} template_keys_exp={exmtemplateKeys} handleChangeInputs={handleChangeInputs} handleDefaultStatusCodes={handleDefaultStatusCodes} templateContent={templateContent} templateSubject={templateSubject} setLoading={setLoading} showModal={showTestModal} handleClose={handleClose} />)}
        </div>
    )
}

export default TemplateBox;