import React, { useContext, useState } from 'react';
import { templateEmailDelete } from '../../api';
import { useHistory } from "react-router-dom";
import { UserContext } from '../../context/User';
import { useForm } from 'react-hook-form';
import { somethingWentWrongNotice } from '../UI/Notifications';
import { SearchContext } from '../../context/Search';
import Modal from '../UI/Modal';
import { store } from 'react-notifications-component';

const DeleteReport = ({ template_id, closeDeleteModal, showDelete }) => {
  const { setIsLoggedIn, setUser } = useContext(UserContext);
  const history = useHistory();
  const { setSearchTrigger, searchTrigger, setLoading } = useContext(SearchContext);
  const [show, setShow] = useState(showDelete);
  const { handleSubmit } = useForm();

  const handleClose = () => {
      setShow(false);
      closeDeleteModal();
  }
  const handleShow = () => setShow(true);
  const deleteReportNotice = () => {
    store.addNotification({
      title: "Delete Email Template",
      message: "Template has been deleted.",
      type: "success",
      container: "top-right",
      animationIn: ["animate__animated", "animate__slideInRight"],
      animationOut: ["animate__animated", "animate__slideOutRight"],
      dismiss: {
        duration: 5000,
        onScreen: false
      }
    });
  }
  const logout = () => {
    setIsLoggedIn(false);
    setUser({});
    history.push("/");
  }

  const handleDefaultStatusCodes = (status_code) => {
    if (status_code === 440 || status_code === 401) {
      logout();
    } else {
      somethingWentWrongNotice("Delete Report");
    }

    setLoading(false);
  }

  const onSubmit = () => {
    setLoading(true);
    templateEmailDelete(`?template_id=${template_id}`)
      .then(() => {
        handleClose();
        setLoading(false);
        deleteReportNotice();
        setTimeout(() => window.location.reload(), 2000);
      })
      .catch(error => {
        handleDefaultStatusCodes(error.response.status);
        handleClose();
      });
  }

  return (
    <>
      <Modal 
        handleClose={handleClose} 
        show={show}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        buttonLabel="Delete"
      >
        <h5 className="mb-4">Are you sure you want to delete this item?</h5>
      </Modal>
    </>
  )
}

export default DeleteReport;
