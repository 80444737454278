import React, { useState, useEffect, useContext } from 'react';
import Loading from '../Loading';
import { Container, Row, Col } from 'react-bootstrap';
import { useLocation, useHistory } from "react-router-dom";
import TemplateBox from './TemplateBox';
import { emailTemplateKeys } from '../../api';
import { somethingWentWrongNotice } from '../UI/Notifications';
import { UserContext } from 'context/User';

const EmailTemplateCreate = ({ setTrigger, trigger }) => {
  const useQuery = () => new URLSearchParams(useLocation().search);
  const { setIsLoggedIn, setUser } = useContext(UserContext);
  const query = useQuery();
  const type = query.get("type");
  const id_template_type = query.get("template_email_by_type");
  const [loading, setLoading] = useState(false);
  const [templateKeys, setTemplateKeys] = useState({});
  const history = useHistory();
  const title = 'Create Email Template'; 

  const [isMounted, setIsMounted] = useState(false)

  const logout = () => {
    setIsLoggedIn(false);
    setUser({});
    history.push('/');
  }

  const handleDefaultStatusCodes = (status_code, message) => {
    if (status_code === 440 || status_code === 401) {
      logout()
    } else {
      somethingWentWrongNotice(message ? message : "Inbox");
    }
    setLoading(false);
  }

  useEffect(() => {
    setLoading(true);
    emailTemplateKeysRequest()
    setTrigger(!trigger);
    return () => {
      setIsMounted(false)
    }
  }, []);


  const emailTemplateKeysRequest = () => {
    emailTemplateKeys()
      .then(response => {
        // let response_key = response.data
        // console.log(response_key[id_template_type])
        setTemplateKeys(response.data);
        setIsMounted(true)
        setLoading(false);
      })
      .catch(error => {
        handleDefaultStatusCodes(error.response.status);
        setLoading(false);
      })
  }


  return (
    <section className="section-spaces">
      <Container>
        <Row>
          <Col>
            <h3 className="mb-5">
              {title}
            </h3>
          </Col>
        </Row>
        <Row>
          <Col>
            {isMounted && <TemplateBox 
              templateKey={templateKeys}
              type={type}
              id_template_type={id_template_type}
              handleDefaultStatusCodes={handleDefaultStatusCodes}
              setLoading={setLoading}
            />}
          </Col>
        </Row>
      </Container>
      {loading && <Loading />}
    </section>  
  )
}

export default EmailTemplateCreate;