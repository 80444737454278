import React, { useState, useEffect, useContext } from 'react';
import { useForm } from 'react-hook-form';
import Modal from '../UI/Modal';
import TextInput from '../UI/TextInput';
import { UserContext } from 'context/User';
import { sendTestTemplateEmails } from '../../api';


const SendTestEmail = ({ onChangeEmailField, userEmailField, replyMessageNotice, showModal, template_keys_exp, handleChangeInputs, templateContent, templateSubject, setLoading, handleDefaultStatusCodes, handleClose }) => {
    const [showAdvSettings, setShowAdvSettings] = useState('none');
    const [advBtnState, setAdvBtnState] = useState('Show')
    const { handleSubmit } = useForm();
    const userEmailValue = useContext(UserContext).user.email;
    // const [userEmailField, setUserEmailField] = useState(userEmailValue);
    // const body_field = document.getElementsByName('body')
    // const body_text = body_field[0].defaultValue
    // console.log(template_keys_exp)

    const extractValues = (text) => {
        // Regular expression to match values inside double curly braces
        const regex = /{{\s*(\w+)\s*}}/g;
        // Use matchAll to handle cases with multiple values
        const matches = text.matchAll(regex);
        // Extract values from matches
        const values = [];
        for (const match of matches) {
          values.push(match[1]);
        }
        // console.log(values)
        for ( let key in template_keys_exp) {
            if (document.getElementsByName(key)[0]){
                document.getElementsByName(key)[0].disabled = true
            }
        }
        for (const value of values) {
            if (document.getElementsByName(value)[0]){
                document.getElementsByName(value)[0].disabled = false
            }
        }
        return values;
    }  

    const sendTestEmail = () => {
        testEmailRequest()
        // setLoading(false);
    }

    const testEmailRequest = () => {
        setLoading(true);
        let data_test_email = {
            content: templateContent,
            recipient: userEmailField,
            subject: templateSubject,
            data: template_keys_exp
        }

        // console.log(data_test_email)

        sendTestTemplateEmails(data_test_email)
            .then(() => {
                setLoading(false);
                handleClose()
                replyMessageNotice('Success!', 'email', 'Your test email has been sent successfully!')
                // history.push(link);
            })
            .catch(error => {
                handleDefaultStatusCodes(error.response.status, error.response.data.detail);
            });
    }

    return (
        <>
            <Modal
                handleClose={handleClose}
                show={showModal}
                handleSubmit={handleSubmit}
                onSubmit={sendTestEmail}
                buttonLabel="Send Test Email"
            >
                <h5 className="mb-4 text-center">Send Test Email</h5>
                <fieldset>
                    <label htmlFor="email_name">Email</label>
                    <TextInput
                        name="email_name"
                        value={userEmailField}
                        onChange={(event) => onChangeEmailField(event.target.value)}
                        required={true}
                    />
                </fieldset>

                <button
                    type='button'                   
                    onClick={() => { if (showAdvSettings === 'none') {setShowAdvSettings('block'); setAdvBtnState('Hide'); extractValues(templateContent)} else { setShowAdvSettings('none'); setAdvBtnState('Show')}}}
                    className='filledBtn mb-3'
                    >
                    {advBtnState} advanced settings
                </button>
                <div className='two-column-div' style={{display: showAdvSettings}}>
                {Object.entries(template_keys_exp).map(([name, value], index) => (
                    <fieldset>
                        <label htmlFor={name}>{name}</label>
                        <TextInput
                        key={`${index}-exmpl`} // Different key to avoid conflicts
                        name={name}
                        value={value}
                        onChange={(event) => handleChangeInputs(name, event)}
                        />
                    </fieldset>
                ))}
                </div>
            </Modal>
        </>
    )
}

export default SendTestEmail;
