import React, { useContext } from 'react';
import { SearchContext } from '../../context/Search';
import FederalContactsTableHeader from './TableHeaders/FederalContactsTableHeader';
import FederalContactsTableBody from './TableBodies/FederalContactsTableBody';
import PurchaseOpportunitiesTableHeader from './TableHeaders/PurchaseOpportunitiesTableHeader';
import PurchaseOpportunitiesTableBody from './TableBodies/PurchaseOpportunitiesTableBody';
import FutureOpportunitiesTableHeader from './TableHeaders/FutureOpportunitiesTableHeader';
import FutureOpportunitiesTableBody from './TableBodies/FutureOpportunitiesTableBody';
import BusinessTableHeader from './TableHeaders/BusinessTableHeader';
import BusinessTableBody from './TableBodies/BusinessTableBody';
import PurchasesTableBody from './TableBodies/PurchasesTableBody';
import PurchasesTableHeader from './TableHeaders/PurchasesTableHeader';
import UsersTableHeader from './TableHeaders/UsersTableHeader';
import UsersTableBody from './TableBodies/UsersTableBody';
import FeedbackReportsTableHeader from './TableHeaders/FeedbackReportsTableHeader';
import FeedbackReportsTableBody from './TableBodies/FeedbackReportsTableBody';
import EmailTemplateByTypeTableHeader from './TableHeaders/EmailTemplateByTypeTableHeader';
import EmailTemplateByTypeTableBody from './TableBodies/EmailTemplateByTypeTableBody';
import EmailTemplatesTypesTableHeader from './TableHeaders/EmailTemplatesTypesTableHeader';
import EmailTemplatesTypesTableBody from './TableBodies/EmailTemplatesTypesTableBody';
import ScheduleEmailsTableHeader from './TableHeaders/ScheduleEmailsTableHeader';
import ScheduleEmailsTableBody from './TableBodies/ScheduleEmailsTableBody';
import ScheduleSmsTableHeader from './TableHeaders/ScheduleSmsTableHeader';
import ScheduleSmsTableBody from './TableBodies/ScheduleSmsTableBody';
import { Table } from 'react-bootstrap';
import classes from './index.module.scss';
import ContractingOfficerTableHeader from './TableHeaders/ContractingOfficerTableHeader';
import ContractingOfficerTableBody from './TableBodies/ContractingOfficerTableBody';

const TableContainer = () => {
  const { active } = useContext(SearchContext);

  return (
    <div className={classes.Table_Wrapper}>
      <Table striped responsive="lg" className={classes.Table}>
        {active === "contract_opportunities" && (
          <>
            <thead className={classes.Table_Head}>
              <FederalContactsTableHeader />
            </thead>
            <tbody className={classes.Table_Body}>
              <FederalContactsTableBody />
            </tbody>
          </>
        )}
        {active === "micro_purchases" && (
          <>
            <thead className={classes.Table_Head}>
              <PurchaseOpportunitiesTableHeader />
            </thead>
            <tbody className={classes.Table_Body}>
              <PurchaseOpportunitiesTableBody />
            </tbody>
          </>
        )}
        {active === "future_opportunities" && (
          <>
            <thead className={classes.Table_Head}>
              <FutureOpportunitiesTableHeader />
            </thead>
            <tbody className={classes.Table_Body}>
              <FutureOpportunitiesTableBody />
            </tbody>
          </>
        )}
        {active === "businesses" && (
          <>
            <thead className={classes.Table_Head}>
              <BusinessTableHeader />
            </thead>
            <tbody className={classes.Table_Body}>
              <BusinessTableBody />
            </tbody>
          </>
        )}
        {active === "b2b_opportunities" && (
          <>
            <thead className={classes.Table_Head}>
              <PurchasesTableHeader />
            </thead>
            <tbody className={classes.Table_Body}>
              <PurchasesTableBody />
            </tbody>
          </>
        )}
        {active === "users" && (
          <>
            <thead className={classes.Table_Head}>
              <UsersTableHeader />
            </thead>
            <tbody className={classes.Table_Body}>
              <UsersTableBody />
            </tbody>
          </>
        )}
        {active === "feedback_reports" && (
          <>
            <thead className={classes.Table_Head}>
              <FeedbackReportsTableHeader />
            </thead>
            <tbody className={classes.Table_Body}>
              <FeedbackReportsTableBody />
            </tbody>
          </>
        )}
        {active === "template_email_by_type" && (
          <>
            <thead className={classes.Table_Head}>
              <EmailTemplateByTypeTableHeader />
            </thead>
            <tbody className={classes.Table_Body}>
              <EmailTemplateByTypeTableBody />
            </tbody>
          </>
        )}
        {active === "template_emails_types" && (
          <>
            <thead className={classes.Table_Head}>
              <EmailTemplatesTypesTableHeader />
            </thead>
            <tbody className={classes.Table_Body}>
              <EmailTemplatesTypesTableBody />
            </tbody>
          </>
        )}
        {active === "schedule_emails" && (
          <>
            <thead className={classes.Table_Head}>
              <ScheduleEmailsTableHeader />
            </thead>
            <tbody className={classes.Table_Body}>
              <ScheduleEmailsTableBody />
            </tbody>
          </>
        )}
        {active === "schedule_sms" && (
          <>
            <thead className={classes.Table_Head}>
              <ScheduleSmsTableHeader />
            </thead>
            <tbody className={classes.Table_Body}>
              <ScheduleSmsTableBody />
            </tbody>
          </>
        )}
        {active === "contracting_officer" && (
          <>
            <thead className={classes.Table_Head}>
              <ContractingOfficerTableHeader />
            </thead>
            <tbody className={classes.Table_Body}>
              <ContractingOfficerTableBody />
            </tbody>
          </>
        )}
      </Table>
    </div>
  )
}

export default TableContainer;