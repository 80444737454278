import React, { useState, useContext } from 'react';
import classes from '../index.module.scss';
import { SearchContext } from '../../../context/Search';
import moment from 'moment';
import { HashLink } from 'react-router-hash-link';
import { Link } from 'react-router-dom';
import DeleteReport from '../../EmailTemplateByType/DeleteReport'
import { templateEmailPost } from '../../../api';
import Loading from '../../Loading';
import { useHistory } from 'react-router-dom';
import { store } from 'react-notifications-component';

const EmailTeplateByTypeTableBody = () => {
  const { searchedData } = useContext(SearchContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setDeleteId('');
  }

  const onDeleteReport = (e, id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
    e.preventDefault();
  }

  const replyMessageNotice = (title) => {
    store.addNotification({
        title: title,
        message: `Your Email Template has been duplicated.`,
        type: "success",
        container: "top-right",
        animationIn: ["animate__animated", "animate__slideInRight"],
        animationOut: ["animate__animated", "animate__slideOutRight"],
        dismiss: {
            duration: 5000,
            onScreen: false
        }
    });
}

const somethingWentWrongNotice = (operationName) => {
  store.addNotification({
      title: operationName,
      message: "Something went wrong...",
      type: "danger",
      container: "top-right",
      animationIn: ["animate__animated", "animate__slideInRight"],
      animationOut: ["animate__animated", "animate__slideOutRight"],
      dismiss: {
          duration: 5000,
          onScreen: false
      }
  });
};

  const onDuplicate = (content, template_type_id, name, subject) => {
    setLoading(true)
    const body = JSON.stringify({
      content: content,
      is_active: false,
      template_type_id: template_type_id,
      name: `${name} Copy`,
      subject: subject
  })
    templateEmailPost(body)
    .then((response) => {
        setLoading(false);
        replyMessageNotice("Success!")
        // setTimeout(() => history.push(link), 2000);
        setTimeout(() => history.push(`email-template?template_id=${response.data[0].id}&template_type_id=${response.data[0].template_type_id}`), 2000);
        
    })
    .catch(error => {
      somethingWentWrongNotice('Failure');
      setLoading(false)
        // handleClose();
    });
  }
  // const reorderedData = [];

  const reorderSearchedData = (searchedData) => {
    return searchedData.reduce((acc, item) => {
      if (item.is_default) {
        acc.unshift(item);
      } else if (item.is_active) {
        // Insert after the default item (or at the beginning if no default)
        const defaultIndex = acc.findIndex(i => i.is_default);
        acc.splice(defaultIndex + 1, 0, item);
      } else {
        acc.push(item);
      }
      return acc;
    }, []);
  };


  return (
    <>
      {

        reorderSearchedData(searchedData).map((item, i) => {
          // searchedData.map((item, i) => {
          const { id, name, content, is_active, is_default, subject, template_type_id } = item;
          return (
            <tr
              key={id}
              style={is_default ? { backgroundColor: '#f1ebe1' } : { backgroundColor: '#fff' }}
            >
              {/* <td className={`${classes.Title_Bold} ${classes.Vertical_Middle}`}>{id}</td> */}
              <td className={`${classes.Title_Normal} ${classes.Vertical_Middle}`}>{name}</td>
              {/* <td className={`${classes.Title_Normal} ${classes.Vertical_Middle}`}>{subject}</td> */}
              <td className={`${classes.Title_Normal} ${classes.Vertical_Middle}`} style={{ padding: '0.1rem' }}>
                <span
                  className={`${is_active ? "responded" : "not_responded"} general`} style={{ fontSize: '0.875em' }}
                >
                  {is_active ? "Active" : "Inactive"}
                </span>
              </td>
              <td className={`${classes.Title_Normal} ${classes.Vertical_Middle}`}>{is_default ? <span style={{ color: '#fff', backgroundColor: '#233539', fontSize: '0.875em', lineHeight: '1.5', borderRadius: '25px', padding: '1px 10px' }}>Default</span> : ""}</td>
              <td className={classes.Table_Options} style={{ display: 'flex' }}>
                <Link
                  to={`/email-template?template_id=${id}&template_type_id=${template_type_id}`}
                  className="btnSizes filledBtn"
                >
                  View
                </Link>
                <Link
                  to={`/update-email-template?template_id=${id}&template_type_id=${template_type_id}`}
                  className="btnSizes transparentBtn ml-2"
                >
                  Edit
                </Link>
                {is_default ?
                  <button
                    onClick={() => onDuplicate(content, template_type_id, name, subject)}
                    className="btnSizes defaultBtn ml-2"
                    style={{border: '1px solid #000'}}
                  >
                    Duplicate
                  </button>
                  :
                  <HashLink
                    onClick={(e) => onDeleteReport(e, id)}
                    to='#'
                    className="btnSizes defaultBtn ml-2"
                    smooth
                  >
                    Delete
                  </HashLink>
                }
              </td>
            </tr>
          )
        })
      }

      {showDeleteModal && <DeleteReport template_id={deleteId} closeDeleteModal={closeDeleteModal} showDelete={showDeleteModal} />}
      {loading && <Loading />}
    </>
  )
}

export default EmailTeplateByTypeTableBody;
