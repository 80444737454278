import React, { useState } from 'react';
import classes from './index.module.scss';
import { Link, useHistory } from 'react-router-dom';
// import { feedbackReportsDelete, messageDelete } from '../../api';
import DeleteReport from '../FeedbackReports/DeleteReport';

const MessageOptions = ({ type, template_type_id, submitRef, handleDefaultStatusCodes, setLoading }) => {
  const history = useHistory();
  // const [showDeleteModal, setShowDeleteModal] = useState(false)
  // const closeDeleteModal = () => {
  //   setShowDeleteModal(false);
  // }

  const link = template_type_id ? `/email-template-by-type?template_email_by_type=${template_type_id}` : `/email-templates-types`;

  return (
    <div className={classes.Message_Footer} style={{display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap'}}>
      <div>
        <button
          ref={submitRef}
          type="submit"
          className='btnSizes filledBtn'
        >
          Create
        </button>
        <Link
          to={link}
          className="btnSizes transparentBtn border-0 ml-5"
        >
          Cancel
        </Link>
      </div>
    </div>
  )
}

export default MessageOptions;