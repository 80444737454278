import React, { useState } from 'react';
import classes from './index.module.scss';
import { Link, useHistory } from 'react-router-dom';
import { templateEmailDelete } from '../../api';
import DeleteReport from './DeleteReport';
import { HashLink } from 'react-router-hash-link';

const MessageOptions = ({ default_state, template_id, template_type_id, handleDefaultStatusCodes, setLoading }) => {
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState('');

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    setDeleteId('');
  }

  const onDeleteReport = (e, id) => {
    setShowDeleteModal(true);
    setDeleteId(id);
    e.preventDefault();
  }

  const link = `/email-template-by-type?template_email_by_type=${template_type_id}`;

  const deleteMessage = () => {
    emailRequest()
  }

  const emailRequest = () => {
    setLoading(true);
    templateEmailDelete(`?template_id=${template_id}`)
      .then(() => {
        setLoading(false);
        history.push(link);
      })
      .catch(error => {
        handleDefaultStatusCodes(error.response.status);
      });
  }

  return (
    <div className={classes.Message_Footer}>
      <div>
        <Link
          to={`/update-email-template?template_id=${template_id}&template_type_id=${template_type_id}`}
          className="btnSizes btnSizes filledBtn"
        >
          Edit
        </Link>
        <Link
          to={link}
          className={"btnSizes transparentBtn border-0 ml-5"}
        >
          Cancel
        </Link>
      </div>
      {default_state ? '' :
        <HashLink
          onClick={(e) => onDeleteReport(e, template_id)}
          to='#'
          className="btnSizes defaultBtn ml-4"
          smooth
        >
          Delete
        </HashLink>
      }
      { showDeleteModal && <DeleteReport deleteMessage={deleteMessage} closeDeleteModal={closeDeleteModal} showDelete={showDeleteModal}/> }
    </div>
  )
}

export default MessageOptions;